import React, { useEffect, useState } from "react";
import defaultImage from "../assets/img/logo.png";
import useAuth from "../hooks/useAuth";
import useLoggedIn from "../hooks/useLoggedIn";

const Product = ({product}) => {

	const { auth } = useAuth();
	const isLoggedIn = useLoggedIn();
	const [isLoggedInState, setIsLoggedInState] = useState(auth?.user);

	useEffect(() => {
		const checkIfLoggedIn = async () => await isLoggedIn();
		checkIfLoggedIn();
	}, []);

	useEffect(() => setIsLoggedInState(auth?.user), [auth]);

	function addDefaultSrc(e) {
		e.target.src = defaultImage;
	}

	return (
		<div className="flex mt-6">
			<div className="flex-col w-1/2 mx-4">
				<img className="h-auto w-full border-red-50 border-2" onError={ addDefaultSrc } src={ process.env.GATSBY_WORDPRESS_BASE_URL + "/wp-content/uploads/product-images/" + product.sku + ".jpg" } alt={ product.name }></img>
			</div>
			<div className="flex-col w-1/2 mx-4 pb-6">
				<small className="mb-4">Cód. { product.sku }</small>
				<div className="text-xl mb-6">{ product.name }</div>
				{
					isLoggedInState
						?   <div className="text-xl font-bold">
							{
								product.price === 0 ? "AGOTADO"
									: `$ ${product.price}`
							}
						</div>
						: null
				}
			</div>
		</div>
	);
};

export default Product;