import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Product from '../components/Product';

const product = (props) => {

    const product = props.data.products;

    return (
        <Layout hasSlider={false}>
            <Product product={ product } />
        </Layout>
    )
}

// Var $sku corresponds to var declared in gatsby-node.js
export const pageQuery = graphql`
  query ($sku: String) {
    products(sku: {eq: $sku}) {
      sku
      name
      price
      category
      slug
    }
  }
`

export default product